import React, { useState } from 'react'
import Logo from '../assets/img/logo.png'
import ResumePDF from '../assets/img/resume.pdf'
// import {Link, useLocation} from "react-router-dom";
import {Link} from "react-router-dom";

export const Header = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const NavIcons = () => {
        if(!showMobileMenu){
            return (
                <svg className="w-6 h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
            )
        }
        return (
            <svg className="w-8 h-8 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
        )
    }
    const linkClass = "text-white block md:inline-flex items-center px-1 text-lg font-semibold nav"
    return (
        <>
            <header>
                <nav className="w-full pt-1 bg-black fixed z-40" id="header_div">
                    <div className="relative md:flex md:justify-between px-2 sm:px-6 md:px-20 py-4 items-center">
                        <div className="inline-flex items-center">
                            <button onClick={() => setShowMobileMenu(!showMobileMenu)} className="text-gray-600 inline-flex p-2 rounded md:hidden outline-none">
                                <NavIcons />
                                <span className='sr-only text-sm text-white'>Button</span>
                            </button>
                            <Link to="/" className="py-2 ml-4 inline-flex items-center">
                                <div className="flex items-center space-x-1.5">
                                    <img src={Logo} alt="Tobi Da-Silva Logo" className="w-28" />
                                </div>
                            </Link>
                        </div>
                        <div className='hidden md:flex justify-end pt-1'>
                            <ul className="lg:ml-6 lg:flex lg:space-x-8 py-2 md:py-0">
                                <li className='relative pb-3'>
                                    <Link
                                        to="/about"
                                        className={linkClass}
                                    >
                                        About
                                    </Link>
                                </li>
                                <li className='relative pb-3 hidden'>
                                    <a
                                        target="_blank" rel="noreferrer"
                                        href={ResumePDF} 
                                        className={linkClass}
                                    >
                                        Resume
                                    </a>
                                </li>
                                <li className='relative pb-3'>
                                    <a
                                        target="_blank" rel="noreferrer" 
                                        href="mailto:dasilva.oyin@gmail.com?subject = Contact Me&body = " 
                                        className={linkClass}
                                    >
                                        Contact
                                    </a>
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </nav>
            </header>
            <div className={`bg-black flex flex-col md:hidden fixed top-0 w-full h-screen z-40 overflow-hidden transition-all ${showMobileMenu ? 'translate-x-[0%] opacity-100' : 'translate-x-[-100%] opacity-0'}`}>
                <div className='flex justify-end p-4'>
                    <button onClick={() => setShowMobileMenu(!showMobileMenu)} className="text-gray-600 inline-flex p-2 rounded outline-none">
                        <NavIcons />
                    </button>
                </div>
                <div className="flex-col flex space-y-4 items-center h-full py-8 md:py-0">
                    <Link to="/" className="py-2 ml-4 inline-flex items-center">
                        <div className="flex items-center space-x-1.5">
                            <img src={Logo} alt="QuickRemit Logo" className="w-28" />
                        </div>
                    </Link>
                    <Link
                        to="/"
                        className={linkClass}
                    >
                        Home
                    </Link>
                    <Link
                        to="/about"
                        className={linkClass}
                    >
                        About
                    </Link>
                    <Link
                        to="/"
                        className={linkClass}
                    >
                        Resume
                    </Link>
                
                    <a
                        target="_blank" rel="noreferrer" 
                        href="mailto:dasilva.oyin@gmail.com?subject = Contact Me&body = " 
                        className={linkClass}
                    >
                        Contact
                    </a>
                </div>
            </div>
        </>
    )
}
